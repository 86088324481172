import React from 'react';

function App() {
  return (
    <div className="container">
      <h1>The Server Room Project</h1>
      <ul>
        <li>Room inventory</li>
        <li>Room upgrade wizard</li>
        <li>Monitoring</li>
        <li>Full 3D view of the room</li>
        <li>More to come... <span className="blinking-cursor">|</span></li>
      </ul>
    </div>
  );
}

export default App;
